import React from "react";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../components/layout";
import SEO from "../components/seo";
import BackgroundImage from "gatsby-background-image";
import { useStaticQuery, graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Image from "gatsby-image";
import { getFluid } from "../util/helper";
import Product from "../components/product";
import GetInTouch from ".././components/get-in-touch";

const ServicesPage = () => {
	const { t } = useTranslation();

	const getInTouchArray: string[] = [
		t("Do you have a project but don’t know where to start?"),
		t(
			"Are you looking for a reliable service provider who will support you during the entire project and even after?"
		),
		t("Already received several offers to your project but not satisfied with the proposals?"),
	];

	const data = useStaticQuery(graphql`
		query ServicesPageQuery {
			allFile(
				filter: {
					relativePath: {
						in: [
							"banner/services.jpg"
							"pci_dss_logo.png"
							"microsoft_silver.png"
							"office_desk.jpg"
							"office_door_logo.jpg"
							"office_street_view.jpg"
						]
					}
				}
			) {
				edges {
					node {
						relativePath
						childImageSharp {
							fluid(quality: 100, maxWidth: 1920) {
								...GatsbyImageSharpFluid
								...GatsbyImageSharpFluidLimitPresentationSize
							}
						}
					}
				}
			}
		}
	`);

	const fluidBanner = getFluid(data.allFile.edges, "banner/services.jpg");

	const imgMicrosoftFluid = getFluid(data.allFile.edges, "microsoft_silver.png");
	const imgPciDssFluid = getFluid(data.allFile.edges, "pci_dss_logo.png");

	const imgOfficeDeskFluid = getFluid(data.allFile.edges, "office_desk.jpg");
	const imgDoorLogoFluid = getFluid(data.allFile.edges, "office_door_logo.jpg");
	const imgStreetViewFluid = getFluid(data.allFile.edges, "office_street_view.jpg");

	return (
		<Layout>
			<SEO title="Services" />
			<div className="text-brand-grayt">
				{/* Banner */}
				<BackgroundImage Tag="div" fluid={fluidBanner}>
					<div className="container text-white h-400px">
						<div className="md:w-4/5 lg:w-2/3 xl:w-1/2 px-4 highlight">
							<h1 className="pt-24 sm:pt-32 text-3xl sm:text-4xl leading-tight font-bold">
								<span>
									<Trans>services:Cloud, Development & Consulting</Trans>
								</span>
							</h1>
							<p className="text-lg mt-8">
								<span>
									<Trans i18nKey="We_deliver_custom" ns="services">
										We deliver custom software development projects, cloud solutions and help to
										execute IT strategies, overcome business challenges.
									</Trans>
								</span>
							</p>
						</div>
					</div>
				</BackgroundImage>
			</div>
			<div className="bg-white">
				<div className="container font-semibold flex justify-between">
					<div></div>
					<div className="hover:text-brand-green text-lg">
						<AnchorLink to="/services#cloud">
							<Trans>services:Cloud</Trans>
						</AnchorLink>
					</div>
					<div className="hover:text-brand-green text-lg">
						<AnchorLink to="/services#development">
							<Trans>services:Development</Trans>
						</AnchorLink>
					</div>
					<div className="hover:text-brand-green text-lg">
						<AnchorLink to="/services#consulting">
							<Trans>services:Consulting</Trans>
						</AnchorLink>
					</div>
					<div></div>
				</div>
			</div>
			<div className="bg-brand-gray4">
				<div className="container w-full p-4">
					{/* Cloud */}
					<div id="cloud" className="flex mt-4 mb-4 p-4">
						<div>
							<div className="text-3xl font-bold">
								<Trans>services:Cloud</Trans>
							</div>
							<div className="border border-1 border-brand-green"></div>
						</div>
					</div>
					{/* Cloud migration and optimiztion */}
					<div className="container px-4 mb-8">
						<h1 className="font-semibold text-2xl mb-4">
							<Trans>services:Cloud Migration and Optimization</Trans>
						</h1>
						<div className="text-lg">
							<Trans i18nKey="Secure_system_migration" ns="services">
								Secure system migration and optimization is always challenging under 24/7 operations –
								however, we know the solution.
							</Trans>
							<br />
							<Trans i18nKey="With_rehost-refactor-rearchitect" ns="services">
								With Rehost-Refactor-Rearchitect and Rebuild approach we deliver the most ideal
								solution.
							</Trans>
						</div>
					</div>
					<div className="container px-4 mb-8 sm:flex">
						<div className="text-lg w-full sm:w-1/2 mr-12">
							<h1 className="font-semibold text-xl mb-3">
								<Trans>services:Assessment</Trans>
							</h1>
							<div className="text-base">
								<Trans i18nKey="Discovering infrastructure components" ns="services">
									Discovering infrastructure components, performance and workload measures,
									identifying underutilized resources and areas to improve and plan auto-scaling
									IaaS/PaaS solutions.
								</Trans>
							</div>
							<h1 className="font-semibold text-xl mt-6 mb-3">
								<Trans>services:Support</Trans>
							</h1>
							<div className="text-base mb-2">
								<Trans i18nKey="Setting up logging, monitoring, auditing " ns="services">
									Setting up logging, monitoring, auditing and alerting to support smooth 24/7
									operations.
								</Trans>
							</div>
							<div className="text-base">
								<Trans i18nKey="Security standards such as" ns="services">
									Security standards such as PCI DSS or GDPR are fully supported.
								</Trans>
							</div>
						</div>
						<div className="text-lg w-full sm:w-1/2 mr-12">
							<h1 className="font-semibold text-xl mt-6 sm:mt-0 mb-3">
								<Trans>services:Implementation</Trans>
							</h1>
							<div className="text-base mb-2">
								<Trans i18nKey="Developing a migration project" ns="services">
									Developing a migration project that contains cloud solution assessment, system and
									software architecture design.
								</Trans>
							</div>
							<div className="text-base">
								<Trans i18nKey="Cloud migration and implementation" ns="services">
									Cloud migration and implementation with minimal or no downtime aligning it with the
									defined business objectives.
								</Trans>
							</div>
						</div>
					</div>
					{/* Microsoft */}
					<div className="flex justify-center mx-4 p-4 bg-white">
						<div className="sm:text-xl mt-1 md:mt-4 lg:mt-8 sm:mr-12">
							<Trans i18nKey="We are Microsoft Silver Cloud" ns="services">
								We are Microsoft Silver Cloud Platform Partners
							</Trans>
						</div>
						<div style={{ width: "213px" }} className="inline-block">
							<Image fluid={imgMicrosoftFluid} />
						</div>
					</div>
					{/* Operations and support */}
					<div className="container px-4 mt-12 mb-8">
						<h1 className="font-semibold text-2xl mb-4">
							<Trans>services:24/7 Operations and Support</Trans>
						</h1>
						<div className="text-lg">
							<Trans i18nKey="Highly available and scalable" ns="services">
								Highly available and scalable cloud system support and 24/7 operations can be
								challenging – however, we have the right tools and solution.
							</Trans>
						</div>
					</div>
					<div className="container px-4 mb-8 sm:flex">
						<div className="text-lg w-full sm:w-1/2 mr-12">
							<h1 className="font-semibold text-xl mb-3">
								<Trans>services:Controlled Cloud Environment</Trans>
							</h1>
							<div className="text-base">
								<Trans i18nKey="Operation of an automated and monitored" ns="services">
									Operation of an automated and monitored cloud environment with proactive reaction to
									events, alerting, availability reporting and client facing dashboards.
								</Trans>
							</div>
							<h1 className="font-semibold text-xl mt-6 mb-3">
								<Trans>services:Process Management</Trans>
							</h1>
							<div className="text-base mb-2">
								<Trans i18nKey="ITIL based management processes" ns="services">
									ITIL based management processes, periodic assessment and reporting.
								</Trans>
							</div>
							<div className="text-base">
								<Trans i18nKey="Automated deployments, CI/CD" ns="services">
									Automated deployments, CI/CD pipeline setup, release management and infrastructure
									scaling on demand.
								</Trans>
							</div>
						</div>
						<div className="text-lg w-full sm:w-1/2 mr-12">
							<h1 className="font-semibold text-xl mt-6 sm:mt-0 mb-3">
								<Trans>services:Secure Operation</Trans>
							</h1>
							<div className="text-base mb-2">
								<Trans i18nKey="Reaching compliance and remaining" ns="services">
									Reaching compliance and remaining compliant such as GDPR, PCI DSS and others.
								</Trans>
							</div>
							<div className="text-base">
								<Trans i18nKey="Analysing application logs" ns="services">
									Analysing application logs, identify unwanted events and provide solution proposal.
								</Trans>
							</div>
						</div>
					</div>
					{/* PCI DSS */}
					<div className="flex justify-center mx-4 p-4 bg-white h-24 sm:h-32">
						<div className="sm:text-xl sm:mt-2 sm:mt-6 md:mt-8 sm:mr-12">
							<Trans i18nKey="We do PCI DSS certificate" ns="services">
								We do PCI DSS certificate in the cloud
							</Trans>
						</div>
						<div style={{ width: "190px" }} className="ml-2 sm:ml-0 inline-block mt-3">
							<Image fluid={imgPciDssFluid} />
						</div>
					</div>
					<div className="container px-4 my-8">
						<h1 className="font-semibold text-2xl mb-4">
							<Trans>services:PCI DSS Compliancy</Trans>
						</h1>
						<div className="text-lg">
							<Trans i18nKey="Having a PCI DSS compliant eCommerce" ns="services">
								Having a PCI DSS compliant eCommerce system in the cloud is challenging – however, we
								have the experience and solution.
							</Trans>
							<br />
							<Trans i18nKey="PCI DSS compliance in the cloud" ns="services">
								PCI DSS compliance in the cloud includes not only the platform but also the process and
								vendors. It is important to know in shared environments who is responsible for what. It
								is not enough to reach compliance, it has to be maintained continuously.
							</Trans>
						</div>
					</div>
					<div className="container px-4 mb-8 sm:flex">
						<div className="text-lg w-full sm:w-1/2 mr-12">
							<h1 className="font-semibold text-xl mb-3">
								<Trans>services:Vulnerability Management</Trans>
							</h1>
							<ul className="list-disc ml-5 mb-2">
								<li>
									<Trans>services:Security hardening and protection</Trans>
								</li>
								<li>
									<Trans i18nKey="Monitoring and tracking" ns="services">
										Monitoring and tracking security events to protect against security issues,
										vulnerabilities
									</Trans>
								</li>
								<li>
									<Trans i18nKey="Collecting audit trail" ns="services">
										Collecting audit trail for all PCI events
									</Trans>
								</li>
								<li>
									<Trans i18nKey="Threat detection" ns="services">
										Threat detection: Monitor network and system configurations, patching
									</Trans>
								</li>
							</ul>
							<h1 className="font-semibold text-xl mt-6 mb-3">
								<Trans>services:Access Control</Trans>
							</h1>
							<ul className="list-disc ml-5 mb-2">
								<li>
									<Trans>services:Limiting user access and tracking user activities</Trans>
								</li>
								<li>
									<Trans>services:Registering access</Trans>
								</li>
								<li>
									<Trans>services:Restrict physical access</Trans>
								</li>
								<li>
									<Trans>services:Automating reviews and reports</Trans>
								</li>
							</ul>
						</div>
						<div className="text-lg w-full sm:w-1/2 mr-12">
							<h1 className="font-semibold text-xl mt-6 sm:mt-0 mb-3">
								<Trans>services:Monitoring, Testing and Documentation</Trans>
							</h1>
							<ul className="list-disc ml-5 mb-2">
								<li>
									<Trans i18nKey="Setting up logging, monitoring" ns="services">
										Setting up logging, monitoring, auditing, alerting on resources; separating SIEM
										and audit logs
									</Trans>
								</li>
								<li>
									<Trans i18nKey="Notification of non-compliant" ns="services">
										Notification of non-compliant components (e.g. missing patch)
									</Trans>
								</li>
								<li>
									<Trans i18nKey="Fulfil internal and external security" ns="services">
										Fulfil internal and external security testing and execute recurring operation
										controls
									</Trans>
								</li>
								<li>
									<Trans i18nKey="Maintaining PCI documentation" ns="services">
										Maintaining PCI documentation and manage audits
									</Trans>
								</li>
							</ul>
							<h1 className="font-semibold text-xl mt-6 mb-3">
								<Trans>services:Segregating Card Holder Data Environment</Trans>
							</h1>
							<ul className="list-disc ml-5 mb-2">
								<li>
									<Trans i18nKey="Sensitive data is stored separately" ns="services">
										Sensitive data is stored separately in a safe environment with limited access
									</Trans>
								</li>
								<li>
									<Trans i18nKey="Enhanced network security" ns="services">
										Enhanced network security with password management, encryption
									</Trans>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			{/* Development */}
			<div className="container bg-white">
				<div id="development" className="flex mt-4 mb-8 p-4">
					<div>
						<div className="text-3xl font-bold">
							<Trans>services:Software Development</Trans>
						</div>
						<div className="border border-1 border-brand-green"></div>
					</div>
				</div>
			</div>
			{/* How we work */}
			<div className="container mb-8 p-4 pt-2">
				<h1 className="uppercase font-semibold text-2xl mb-6">
					<span className="text-brand-green mr-2">//</span>
					<Trans>services:How We Work</Trans>
				</h1>
				<div className="text-lg mb-3">
					<Trans i18nKey="We work based on Agile and Lean principles" ns="services">
						We work based on Agile and Lean principles, delivering projects with Kanban, Scrum and Waterfall
						approaches. We provide not only software development but all additional services starting from
						consultation, business analysis, user interaction and user interface planning, designing,
						quality assurance and user acceptance testing, release management and operational support.
					</Trans>
				</div>
				<div className="text-lg">
					<Trans i18nKey="With our complex software development" ns="services">
						With our complex software development services we are able to fully serve our partners so they
						can focus more on their business and build up new ideas to be implemented.
					</Trans>
				</div>
			</div>
			{/* Development timeline */}
			<div className="uppercase container grid grid-cols-6 sm:grid-cols-9 lg:grid-cols-14 text-center px-4 my-12">
				<div className="text-sm font-semibold sm:text-base xl:text-lg shadow-lg h-24 col-span-2 pt-6 sm:pt-5 border-solid border-2 border-gray-100 mb-5">
					<Trans>services:Business Analysis</Trans>
				</div>
				<div className="text-6xl text-brand-green">&#10095;</div>
				<div className="text-sm font-semibold sm:text-base xl:text-lg shadow-lg h-24 col-span-2 pt-6 sm:pt-5 border-solid border-2 border-gray-100 mb-5 px-1">
					<Trans>services:User interface design</Trans>
				</div>
				<div className="text-6xl text-brand-green">&#10095;</div>
				<div className="text-sm font-semibold sm:text-base xl:text-lg shadow-lg h-24 col-span-2 pt-6 sm:pt-5 border-solid border-2 border-gray-100 mb-5">
					<Trans>services:Web / Backend Development</Trans>
				</div>
				<div className="text-6xl text-brand-green">&#10095;</div>
				<div className="text-sm font-semibold sm:text-base xl:text-lg shadow-lg h-24 col-span-2 pt-6 sm:pt-5 lg:pt-4 border-solid border-2 border-gray-100 mb-5 px-1">
					<Trans>services:Quality Assurance</Trans>
				</div>
				<div className="text-6xl text-brand-green">&#10095;</div>
				<div className="text-sm font-semibold sm:text-base xl:text-lg shadow-lg h-24 col-span-2 pt-6 sm:pt-5 lg:pt-4 border-solid border-2 border-gray-100 mb-5">
					<Trans>services:Release &</Trans>
					<br />
					<Trans>services:Support</Trans>
				</div>
			</div>
			{/* Development info-blocks */}
			<div className=" container grid md:grid-cols-3 gap-4 md:gap-12">
				<div className="w-full bg-brand-gray4">
					<div className="p-8">
						<div className="pb-4 text-2xl font-semibold">
							<Trans>services:eCommerce Web Development</Trans>
						</div>
						<div className="text-lg mb-3">
							<Trans i18nKey="Custom eCommerce solutions specialized for high availability" ns="services">
								Custom eCommerce solutions specialized for high availability and high transaction
								numbers including user interface design, infrastructure planning, application
								development, APIs, data storage, automated testing and cloud solutions.
							</Trans>
						</div>
						<div className="text-lg mb-3">
							<Trans i18nKey="We do SEO optimization, Google Analytics" ns="services">
								We do SEO optimization, Google Analytics and Tag Manager setup, CMS introduction, online
								payment and various social login solutions.
							</Trans>
						</div>
					</div>
				</div>
				<div className="w-full bg-brand-gray4">
					<div className="p-8">
						<div className="pb-4 text-2xl font-semibold">
							<Trans>services:Backend System Development</Trans>
						</div>
						<div className="text-lg mb-3">
							<Trans i18nKey="Custom development services for companies" ns="services">
								Custom development services for companies who require high security standards, high
								availability to manage millions of transactions. We seamlessly integrate to an existing
								system or take over the development of the existing application.
							</Trans>
						</div>
						<div className="text-lg mb-3">
							<Trans i18nKey="we_usually_work_with_dot_net" ns="services">
								We usually work with .NET, Git, domain-driven design, continuous integration and
								continuous delivery.
							</Trans>
						</div>
					</div>
				</div>
				<div className="w-full bg-brand-gray4">
					<div className="p-8">
						<div className="pb-4 text-2xl font-semibold">
							<Trans>services:Software Integrations</Trans>
						</div>
						<div className="text-lg mb-3">
							<Trans i18nKey="We usually work with payment providers and acquirers" ns="services">
								We usually work with payment providers and acquirers, delivery and logistic companies,
								CRM systems, accounting and billing platforms and travel service providers like
								insurance, hotel wholesalers, airlines, car rentals, ticket agencies, and many others.
							</Trans>
						</div>
						<div className="text-lg mb-3">
							<Trans i18nKey="The service provider will be integrated" ns="services">
								The service provider will be integrated into the existing system delivering a smooth
								user experience.
							</Trans>
						</div>
					</div>
				</div>
			</div>

			{/* Technologies */}
			<div className="container mb-12 p-4 pt-8">
				<h1 className="uppercase font-semibold text-2xl mb-8">
					<span className="text-brand-green mr-2">//</span>
					<Trans>services:Technologies</Trans>
				</h1>
				<div className="grid grid-cols-3 sm:grid-cols-5 gap-3">
					<div>
						<h1 className="font-semibold text-xl mb-2">
							<Trans>services:Frontend</Trans>
						</h1>
						<ul>
							<li>
								<Trans>services:React / Web Components</Trans>
							</li>
							<li>
								<Trans>services:HTML5 / SCSS</Trans>
							</li>
							<li>
								<Trans>services:ES6 / Typesript</Trans>
							</li>
							<li>
								<Trans>services:iOS / Android</Trans>
							</li>
							<li>
								<Trans>services:SEO / CMS</Trans>
							</li>
						</ul>
					</div>
					<div>
						<h1 className="font-semibold text-xl mb-2">
							<Trans>services:Backend</Trans>
						</h1>
						<ul>
							<li>
								<Trans i18nKey="ASP dotNET" ns="services">
									ASP .NET
								</Trans>
							</li>
							<li>
								<Trans i18nKey="dotNET Core" ns="services">
									.NET Core
								</Trans>
							</li>
							<li>
								<Trans>services:RESTful</Trans>
							</li>
							<li>
								<Trans>services:IIS</Trans>
							</li>
						</ul>
					</div>
					<div>
						<h1 className="font-semibold text-xl mb-2">
							<Trans>services:Business Analysis</Trans>
						</h1>
						<ul>
							<li>
								<Trans>services:Agile / Lean</Trans>
							</li>
							<li>
								<Trans>services:Jira</Trans>
							</li>
							<li>
								<Trans>services:Modelling</Trans>
							</li>
							<li>
								<Trans>services:GA / GTM</Trans>
							</li>
						</ul>
					</div>
					<div>
						<h1 className="font-semibold text-xl mb-2">
							<Trans>services:Data</Trans>
						</h1>
						<ul>
							<li>
								<Trans>services:SQL Server</Trans>
							</li>
							<li>
								<Trans>services:Elasticsearch</Trans>
							</li>
							<li>
								<Trans>services:Redis</Trans>
							</li>
							<li>
								<Trans>services:DWH</Trans>
							</li>
						</ul>
					</div>
					<div>
						<h1 className="font-semibold text-xl mb-2">
							<Trans>services:Quality Assurance</Trans>
						</h1>
						<ul>
							<li>
								<Trans>services:UAT</Trans>
							</li>
							<li>
								<Trans>services:Unit testing</Trans>
							</li>
							<li>
								<Trans>services:APM</Trans>
							</li>
							<li>
								<Trans>services:Cypress</Trans>
							</li>
						</ul>
					</div>
				</div>
			</div>
			{/* Consulting */}
			<div className="bg-brand-gray4 pb-8">
				<div className="container p-4">
					<div id="consulting" className="flex mt-4 mb-2">
						<div>
							<div className="text-3xl font-bold">
								<Trans>services:Consulting</Trans>
							</div>
							<div className="border border-1 border-brand-green"></div>
						</div>
					</div>
				</div>
				<div className="container mb-12 p-4 pt-2">
					<div className="text-lg mb-3">
						<Trans i18nKey="With 25+ years of experience" ns="services">
							With 25+ years of experience in the airline IT management working with major ultra low cost
							airlines, we help to overcome business challenges and efficiently execute the IT strategy.
							DGITAL Labs was founded in 2015 by Zsolt S. Csernak - Head of Technology of Wizz Air between
							2003 and 2015 - and employs over 20 professionals in Budapest, Hungary.
						</Trans>
					</div>
					<div className="text-lg mb-3">
						<Trans i18nKey="Beside consulting, we deliver" ns="services">
							Beside consulting, we deliver custom IT development projects for airlines, tour operators
							and eCommerce businesses while providing 24/7 operations and cloud services. Since 2015 we
							also work on a travel product portfolio that is worth more than 2 million USD valued by
							independent IT auditors.
						</Trans>
					</div>
					<div className="text-lg">
						<Trans i18nKey="We are a Microsoft Cloud Platform Silver" ns="services">
							We are a Microsoft Cloud Platform Silver Partner with a group of Application Developers and
							certified Azure Administrator Associates and Azure Solutions Architect Experts. DGITAL Labs
							is a certified PCI Service Provider and specializes in maintaining and operating eCommerce
							websites according to the PCI DSS compliance requirements.
						</Trans>
					</div>
				</div>
				{/* Consulting Services */}
				<div className="container px-8 pt-8 bg-white">
					<Product
						regular={true}
						fluid={imgDoorLogoFluid}
						alt={t("Dgital Door Logo")}
						body={
							<>
								<h2 className="font-semibold text-3xl mb-4">
									<Trans>services:Airline and Travel IT</Trans>
								</h2>
								<p className="text-lg mb-2">
									<Trans i18nKey="DGITAL Labs’ management has vast" ns="services">
										DGITAL Labs’ management has vast experience in the travel industry gained at
										major international tourism brands such as Wizz Air, Booking.com, Kuoni Travel
										(GTA), Malév Airlines and a variety of leading tour operators.
									</Trans>
								</p>
								<p className="text-lg mb-2">
									<Trans i18nKey="We are familiar with airline passenger" ns="services">
										We are familiar with airline passenger service and operations systems; ERP, CRM
										and CMS implementations; security standards such as PCI DSS and GDPR; data
										warehouse and data governance; eCommerce conversion optimization and SEO
										principles as well as custom IT development and cloud solutions.
									</Trans>
								</p>
								<p className="text-lg mb-2">
									<Trans i18nKey="We have more than 25 years of ULCC airline" ns="services">
										We have more than 25 years of ULCC airline and tour operator IT experience both
										on top and middle management level and delivered a huge variety of IT projects
										including airline and tour operator launches, system migrations, ERP and data
										warehouse implementations and many more.
									</Trans>
								</p>
							</>
						}
					/>
					<Product
						regular={false}
						fluid={imgStreetViewFluid}
						alt="Office Street View"
						body={
							<>
								<h2 className="font-semibold text-3xl mb-4">
									<Trans>services:Cloud Architecture and Data Storage</Trans>
								</h2>
								<p className="text-lg mb-2">
									<Trans i18nKey="DGITAL is an eCommerce focused" ns="services">
										DGITAL is an eCommerce focused technology company that has a unique set of cloud
										know-how and experience gained at leading airlines including cloud-based website
										developments, complex system implementations and 24/7 operations.
									</Trans>
								</p>
								<p className="text-lg mb-2">
									<Trans i18nKey="We have successfully completed more than" ns="services">
										We have successfully completed more than 30 cloud eCommerce development projects
										so far such as operational system migration, cloud migration and optimization,
										cloud data warehouse implementation, cloud -native software developments, ERP
										and CRM/CDP system introduction and many more. Our operating business critical
										systems are continuously handling more than 100M monthly transactions.
									</Trans>
								</p>
								<p className="text-lg mb-2">
									<Trans i18nKey="We are Microsoft Silver Cloud" ns="services">
										We are Microsoft Silver Cloud Platform Partners.
									</Trans>
								</p>
							</>
						}
					/>
					<Product
						regular={true}
						fluid={imgOfficeDeskFluid}
						alt="Office Desk"
						body={
							<>
								<h2 className="font-semibold text-3xl mb-4">
									<Trans>services:eCommerce Solutions</Trans>
								</h2>
								<p className="text-lg mb-2">
									<Trans i18nKey="We have gained massive IT know-how" ns="services">
										We have gained massive IT know-how in the eCommerce business working at and
										working with leading global brands such as KPMG Global Services, Wizz Air and
										JetSMART. We have used our knowledge to build up an eCommerce booking engine and
										white label website meanwhile gaining hands-on experience in developing and
										operating eCommerce businesses.
									</Trans>
								</p>
								<p className="text-lg mb-2">
									<Trans i18nKey="We are IT experts with extensive eCommerce" ns="services">
										We are IT experts with extensive eCommerce experience that is used for the
										benefit of already existing eCommerce enterprises as well as establishing new
										businesses.
									</Trans>
								</p>
							</>
						}
					/>
				</div>
			</div>
			<GetInTouch getInTouchArray={getInTouchArray} />
		</Layout>
	);
};

export default ServicesPage;
